<template>
  <div class="all winning-page">
    <div class="topTitle">
      <div class="title">
        <div class="imgLogo"><img class="home" src="../assets/img/logo2.png">
          <p>环宇建筑科技股份有限公司</p>
        </div>
        <div class="tab_but">
          <router-link :to="{name:'Home'}" class="but_tab"> 网站首页</router-link>
          <router-link :to="{name:'Recruiting'}" class="but_tab"> 招标公告</router-link>
          <router-link :to="{name:'Winning'}" class="but_tab_Winning"> 中标公示</router-link>
          <router-link :to="{name:'Service'}" class="but_tab"> 企业服务</router-link>
          <router-link :to="{name:'Help'}" class="but_tab"> 帮助中心</router-link>
          <div class="but_tab" @click="exitBut" v-if="id !== null">退出登录</div>
        </div>
      </div>
      <!--    搜索表格-->
      <div class="tab_bg tab_bgOne">
        <div class="tab_d">
<!--          <el-form :inline="true" :model="formInline" class="demo-form-inline">-->
<!--            &lt;!&ndash;        招标名称  &ndash;&gt;-->
<!--            <el-form-item label="中标名称:" style="padding-top: 20px;">-->
<!--              <el-input v-model="formInline.company" placeholder="请输入中标名称"></el-input>-->
<!--            </el-form-item>-->
<!--            &lt;!&ndash;        所属项目  &ndash;&gt;-->
<!--            <el-form-item label="中标单位:" style="padding-top: 20px;margin-left: 40px">-->
<!--              <el-input v-model="formInline.region2" placeholder="请输入中标单位"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-button class="Search_but" icon="el-icon-search" type="primary" @click="search">搜索</el-button>-->
<!--          </el-form>-->
          <div class="screening">
            <div style="display: flex; width: 60%; margin: 0 auto">
              <div class="search">
                <i class="el-icon-search"></i>
                <el-input v-model="search_input" class="inputsss" @keyup.enter.native="searchI" placeholder="请输入关键字">
                </el-input>
              </div>
              <button class="ser-button" @click="searchI">搜索</button>
            </div>
            <div class="conditions">
              <p>发布时间：</p>
              <div v-for="(item,index) in screening" :key="index" :class="{active:categoryIndex==item}" class="ScrBut"
                   @click="clickCategory(item)"><p>{{ item }}</p>
              </div>
              <div v-if="visible" style="padding-left: 20px;">
                <el-date-picker
                  v-model="moreTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeMore">
                </el-date-picker>
              </div>
            </div>
          </div>
          <!--        线条-->
          <div class="x"></div>
          <!--   项目名称 招标内容 中标单位 发布时间     表格-->
          <el-table
            :data="WinningName"
            style="width: 100%;margin: 40px -10px;">
            <el-table-column
              label="项目名称"
              width="300"
              align="center">
              <span slot-scope="scope" :title="scope.row.subject">{{scope.row.subject}}</span>
            </el-table-column>
              <el-table-column
                label="招标名称"
                width="270"
                align="center">
                <span slot-scope="scope" :title="scope.row.name">{{scope.row.name}}</span>
              </el-table-column>
<!--            <el-table-column-->
<!--              label="供应商"-->
<!--              prop="supplierName"-->
<!--              width="360"-->
<!--              align="center">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              label="中标价格"-->
<!--              prop="biddingPrice"-->
<!--              width="360"-->
<!--              align="center">-->
<!--            </el-table-column>-->
            <el-table-column
              label="中标单位"
              width="400"
              align="center">
              <span slot-scope="scope" :title="scope.row.supplierNames">{{scope.row.supplierNames}}</span>
            </el-table-column>
            <el-table-column
              label="发布时间"
              width="150"
              align="center">
              <span slot-scope="scope" :title="scope.row.createDate">{{scope.row.biddingReleaseDate}}</span>
            </el-table-column>
          </el-table>

          <div class="pages1">
            <el-pagination
              :page-count="this.pages"
              background
              @current-change="handleCurrentChange"
              layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>

      </div>
    </div>
    <div class="cont"></div>
    <!--    底部-->
    <div class="bottom_d">
      <div class="logo_d">
        <img src="../assets/img/back.png">
        <p>
          地址：浙江省绍兴市凤林西路300号环宇大厦<br>
          电话：0575-85132445 85336908<br>
          传真：0575-85117404 85336900<br>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #FFFFFF">备案：浙ICP备11004969号-1</a>
        </p>
      </div>
      <div class="wx_logo">
        <img src="../assets/img/logo3.png">
        <p>
          微信公众号：hyjzkj
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { exitLogin, RecruitingAt, Winning } from '@/api/api'

export default {
  name: 'Winning',
  data () {
    return {
      pages: 0,
      value: '',
      formInline: {
        company: '', // 中标名称
        region2: ''
      },
      WinningName: [],
      tableData: [],
      company: [],
      entTime: '',
      page: 1,
      total: 0,
      size: 10,
      screening: {
        all: '全部',
        today: '今天',
        days: '最近三天',
        SevenDay: '最近七天',
        AMonth: '最近1个月',
        TMonth: '最近3个月',
        more: '更多'
      },
      categoryIndex: '全部',
      moreTime: '',
      visible: false,
      search_input: '',
      record: 0
    }
  },
  created () {
    // this.rec()
    this.ReMessage()
    this.id = localStorage.getItem('EUJSESSIONID')
  },
  methods: {
    ReMessage () {
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        // tName: '',
        status: 'done',
        _type_: 'date',
        condition: 'createDate',
        value: []
      }, res => {
        // this.ReTingList = res.data.data.data
        this.WinningName = res.data.data.data
        this.pages = res.data.data.pages
      })
    },
    // 退出登录
    exitBut () {
      exitLogin({
        method: 'logout'
      }, res => {
        this.$message.info('已注销用户！')
        this.$router.replace('/login/login')
        this.$cookies.remove('token', 'EUJSESSIONID=egCR0M2aKCuk46H9fucdWyPBsqeZTFGnU%2Fylt4meBeE%3D')
        localStorage.removeItem('EUJSESSIONID')
      })
    },
    home () {
      this.$router.replace('/')
    },
    handleCurrentChange (page) {
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: page,
        // tName: '',
        status: 'done',
        _type_: 'date',
        condition: 'createDate',
        value: []
      }, res => {
        // this.ReTingList = res.data.data.data
        this.WinningName = res.data.data.data
        this.pages = res.data.data.pages
      })
    },
    async rec () {
      axios.defaults.headers.token = localStorage.getItem('token')
      Winning({
        // method: 'suppliers',
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        supplierName_like: '',
        status: 'done'
      }, res => {
        this.total = res.data.data.total
        this.WinningName = res.data.data.data
        for (var i = 0; i < this.WinningName.length; i++) {
          this.tableData = this.WinningName[i].tenderInfoList[0]
          this.tableData.entryTime = this.dateFormat(this.tableData.entryTime)
        }
      })
    },
    async search () {
      axios.defaults.headers.token = localStorage.getItem('token')
      Winning({
        method: 'suppliers',
        size: this.size,
        page: this.page,
        projectName: this.formInline.company,
        supplierName_like: this.formInline.region2
      }, res => {
        this.WinningName = res.data.data.data
        for (var i = 0; i < this.WinningName.length; i++) {
          this.tableData = this.WinningName[i].tenderInfoList[0]
          this.tableData.entryTime = this.dateFormat(this.tableData.entryTime)
        }
      })
    },
    dateFormat (time) {
      // 将13位时间戳转换成时间格式  输出为2018-10-09
      const date = new Date(time)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      var myDate = ''
      myDate = year + '-' + month + '-' + day
      return myDate
    },
    clickCategory (item) { // 这里我们传入一个当前值
      if (item === '更多') {
        this.visible = true
      } else {
        this.visible = false
      }
      var neweTime = new Date()
      if (neweTime.getTime() - this.record > this.time) {
        this.$emit('click')
      }
      this.record = (new Date().getTime())
      this.categoryIndex = item
      if (this.categoryIndex === '全部') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'done',
          _type_: 'date',
          condition: 'createDate',
          value: []
        }, res => {
          // this.ReTingList = res.data.data.data
          this.WinningName = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '今天') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'done',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.dateFormat(Date()),
            this.dateFormat(Date())
          ]
        }, res => {
          this.WinningName = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近三天') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'done',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.getDay(-3),
            this.dateFormat(Date())
          ]
        }, res => {
          this.WinningName = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近七天') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'done',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.getDay(-7),
            this.dateFormat(Date())
          ]
        }, res => {
          this.WinningName = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近1个月') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'done',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.oneMonth(Date()),
            this.dateFormat(Date())
          ]
        }, res => {
          this.WinningName = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近3个月') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'done',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.getDay(-90),
            this.dateFormat(Date())
          ]
        }, res => {
          this.WinningName = res.data.data.data
          this.total = res.data.data.total
        })
      }
    },
    // 根据传参获取日期
    getDay (day) {
      var today = new Date()
      var targe = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targe) // 注意，这行是关键代码

      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },
    changeMore (e) {
      const start = new Date(e[0])
      const end = new Date(e[1])
      const arr = []
      arr.push(start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate())
      arr.push(end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate())
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        // tName: '',
        status: 'done',
        _type_: 'date',
        condition: 'createDate',
        value: arr
      }, res => {
        this.WinningName = res.data.data.data
        this.total = res.data.data.total
      })
    },
    searchI () {
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        // tName: this.search_input,
        subject: this.search_input,
        status: 'done',
        _type_: 'date',
        condition: 'createDate',
        value: []
      }, res => {
        this.WinningName = res.data.data.data
        this.total = res.data.data.total
      })
    }

  }
}
</script>

<style>
.winning-page * {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}
.winning-page .home {
  cursor: pointer;
}

/*全部*/
.winning-page .all {
  position: relative;
  width: 100%;
  height: 1502px;
  margin: 0 auto;
  background-color: #eeeeee;
}

.winning-page .demonstration {
  color: #666666;
  line-height: 80px;
  margin-left: 35px;
}

/*顶部title*/
.winning-page .topTitle {
  width: 100%;
  height: 360px;
  background-color: #ffffff;
  background-image: url("../assets/img/bannerZM.png");
  background-size: 100% 100%;
}

.winning-page .title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}

.winning-page .imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.winning-page .tab_but {
  display: flex;
  width: 600px;
  height: 90px;
  color: #ffffff;
  padding-top: 20px;
  text-align: center;
}

.winning-page .but_tab {
  font-size: 16px;
  margin: 0 10px;
  width: 220px;
  height: 40px;
  line-height: 40px;
  color: white;
  text-decoration: none;
}

.winning-page .but_tab:hover {
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  cursor: pointer;
}

.winning-page .but_tab_Winning {
  font-size: 16px;
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}

.winning-page .tab_bgOne {
  position: relative;
  width: 1200px;
  height: 840px;
  background-color: #ffffff;
  margin: 150px auto;
}
/*分页*/
.pages1{
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  /*background-color: #EA5D5C;*/
  display: flex;
  justify-content: center;
}

.winning-page .tab_d {
  /*padding-left: 50px;*/
  /*padding-top: 20px;*/
  padding: 20px 40px;
  /*width: 100%;*/
  height: 120px;
  /*background-color: #93dce5;*/
}

/*中间占位*/
.winning-page .cont {
  position: relative;
  /*background-color: #10539B;*/
  z-index: -100;
  width: 100%;
  height: 800px;
}

/*底部*/
.winning-page .bottom_d {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 360px;
  background-color: #191919;
  text-align: left;
  margin: 0px auto;
}

.winning-page .logo_d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 250px;
  /*background-color: #554ace;*/
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.winning-page .logo_d > img {
  width: 300px;
  height: 90px;
  margin-left: -28px;
  margin-top: 38px;
}

.winning-page .wx_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 190px;
  /*background-color: #554ace;*/
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.winning-page .wx_logo > img {
  width: 142px;
  height: 142px;
}

/*搜索*/
.winning-page .Search_but {
  width: 100px;
  height: 40px;
  background-color: #10539B;
  color: white;
  border: none;
  margin-top: 20px;
}

.winning-page .screening {
  width: 100%;
  height: 180px;
  /*background: #7ac23c;*/
}

.winning-page .search {
  width: 100%;
  margin: 0 auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #69a0ec;
  padding: 0 20px;
}

.winning-page .inputsss >>> .el-input__inner {
  border: none !important;
}

.winning-page .conditions {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  /*background-color: #EA5D5C;*/
  border-bottom: 1px solid #f6f0f0;
  margin: 40px auto;
}

.winning-page .conditions > p {
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  text-align: right;
}

.winning-page .ScrBut {
  width: 78px;
  height: 25px;
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  border-radius: 2px;
}

.winning-page .ScrBut:hover {
  width: 78px;
  height: 25px;
  background: #fdf3ec;
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff9127;
  border: 1px solid #ff9127;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
}
.winning-page .active {
  width: 78px;
  height: 25px;
  background: #fdf3ec;
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff9127;
  border: 1px solid #ff9127;
  border-radius: 2px;
  box-sizing: border-box;
}

.winning-page .x {
  width: 100%;
  height: 4px;
  background-color: #1789e5;
}

.winning-page .ser-button {
  width: 120px;
  height: 42px;
  color: #FFFFFF;
  background-color: #1789e5;
  margin-left: 10px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
  font-size: 17px;
  font-family: "Microsoft YaHei",微软雅黑;
}

.winning-page .tab_bg .el-table .cell{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.winning-page .tab_bg .el-input__inner {
  border: none;
}
</style>
